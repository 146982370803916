var countryList = [
  { country_code: 'CN', country: 'China', code: '86', rule: 'XXX XXXX XXXX' },
  { country_code: 'JM', country: 'Jamaica', code: '1876', rule: 'XXX XXXX' },
  {
    country_code: 'KN',
    country: 'Saint Kitts & Nevis',
    code: '1869',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'TT',
    country: 'Trinidad & Tobago',
    code: '1868',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'VC',
    country: 'Saint Vincent & the Grenadines',
    code: '1784',
    rule: 'XXX XXXX'
  },
  { country_code: 'DM', country: 'Dominica', code: '1767', rule: 'XXX XXXX' },
  {
    country_code: 'LC',
    country: 'Saint Lucia',
    code: '1758',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'SX',
    country: 'Sint Maarten',
    code: '1721',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'AS',
    country: 'American Samoa',
    code: '1684',
    rule: 'XXX XXXX'
  },
  { country_code: 'GU', country: 'Guam', code: '1671', rule: 'XXX XXXX' },
  {
    country_code: 'MP',
    country: 'Northern Mariana Islands',
    code: '1670',
    rule: 'XXX XXXX'
  },
  { country_code: 'MS', country: 'Montserrat', code: '1664', rule: 'XXX XXXX' },
  {
    country_code: 'TC',
    country: 'Turks & Caicos Islands',
    code: '1649',
    rule: 'XXX XXXX'
  },
  { country_code: 'GD', country: 'Grenada', code: '1473', rule: 'XXX XXXX' },
  { country_code: 'BM', country: 'Bermuda', code: '1441', rule: 'XXX XXXX' },
  {
    country_code: 'KY',
    country: 'Cayman Islands',
    code: '1345',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'VI',
    country: 'US Virgin Islands',
    code: '1340',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'VG',
    country: 'British Virgin Islands',
    code: '1284',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'AG',
    country: 'Antigua & Barbuda',
    code: '1268',
    rule: 'XXX XXXX'
  },
  { country_code: 'AI', country: 'Anguilla', code: '1264', rule: 'XXX XXXX' },
  { country_code: 'BB', country: 'Barbados', code: '1246', rule: 'XXX XXXX' },
  { country_code: 'BS', country: 'Bahamas', code: '1242', rule: 'XXX XXXX' },
  {
    country_code: 'UZ',
    country: 'Uzbekistan',
    code: '998',
    rule: 'XX XXXXXXX'
  },
  {
    country_code: 'KG',
    country: 'Kyrgyzstan',
    code: '996',
    rule: 'XXX XXXXXX'
  },
  { country_code: 'GE', country: 'Georgia', code: '995', rule: 'XXX XXX XXX' },
  {
    country_code: 'AZ',
    country: 'Azerbaijan',
    code: '994',
    rule: 'XX XXX XXXX'
  },
  {
    country_code: 'TM',
    country: 'Turkmenistan',
    code: '993',
    rule: 'XX XXXXXX'
  },
  {
    country_code: 'TJ',
    country: 'Tajikistan',
    code: '992',
    rule: 'XX XXX XXXX'
  },
  { country_code: 'NP', country: 'Nepal', code: '977', rule: 'XX XXXX XXXX' },
  { country_code: 'MN', country: 'Mongolia', code: '976', rule: 'XX XX XXXX' },
  { country_code: 'BT', country: 'Bhutan', code: '975', rule: 'XX XXX XXX' },
  { country_code: 'QA', country: 'Qatar', code: '974', rule: 'XX XXX XXX' },
  { country_code: 'BH', country: 'Bahrain', code: '973', rule: 'XXXX XXXX' },
  { country_code: 'IL', country: 'Israel', code: '972', rule: 'XX XXX XXXX' },
  {
    country_code: 'AE',
    country: 'United Arab Emirates',
    code: '971',
    rule: 'XX XXX XXXX'
  },
  {
    country_code: 'PS',
    country: 'Palestine',
    code: '970',
    rule: 'XXX XX XXXX'
  },
  { country_code: 'OM', country: 'Oman', code: '968', rule: 'XXXX XXXX' },
  { country_code: 'YE', country: 'Yemen', code: '967', rule: 'XXX XXX XXX' },
  {
    country_code: 'SA',
    country: 'Saudi Arabia',
    code: '966',
    rule: 'XX XXX XXXX'
  },
  { country_code: 'KW', country: 'Kuwait', code: '965', rule: 'XXXX XXXX' },
  { country_code: 'IQ', country: 'Iraq', code: '964', rule: 'XXX XXX XXXX' },
  { country_code: 'SY', country: 'Syria', code: '963', rule: 'XXX XXX XXX' },
  { country_code: 'JO', country: 'Jordan', code: '962', rule: 'X XXXX XXXX' },
  { country_code: 'LB', country: 'Lebanon', code: '961' },
  { country_code: 'MV', country: 'Maldives', code: '960', rule: 'XXX XXXX' },
  { country_code: 'TW', country: 'Taiwan', code: '886', rule: 'XXX XXX XXX' },
  { country_code: 'GO', country: 'International Networks', code: '883' },
  { country_code: 'GO', country: 'International Networks', code: '882' },
  { country_code: 'GO', country: 'Global Mobile Satellite', code: '881' },
  { country_code: 'BD', country: 'Bangladesh', code: '880' },
  { country_code: 'LA', country: 'Laos', code: '856', rule: 'XX XX XXX XXX' },
  { country_code: 'KH', country: 'Cambodia', code: '855' },
  { country_code: 'MO', country: 'Macau', code: '853', rule: 'XXXX XXXX' },
  { country_code: 'HK', country: 'Hong Kong', code: '852', rule: 'X XXX XXXX' },
  { country_code: 'KP', country: 'North Korea', code: '850' },
  { country_code: 'MH', country: 'Marshall Islands', code: '692' },
  { country_code: 'FM', country: 'Micronesia', code: '691' },
  { country_code: 'TK', country: 'Tokelau', code: '690' },
  { country_code: 'PF', country: 'French Polynesia', code: '689' },
  { country_code: 'TV', country: 'Tuvalu', code: '688' },
  { country_code: 'NC', country: 'New Caledonia', code: '687' },
  { country_code: 'KI', country: 'Kiribati', code: '686' },
  { country_code: 'WS', country: 'Samoa', code: '685' },
  { country_code: 'NU', country: 'Niue', code: '683' },
  { country_code: 'CK', country: 'Cook Islands', code: '682' },
  { country_code: 'WF', country: 'Wallis & Futuna', code: '681' },
  { country_code: 'PW', country: 'Palau', code: '680' },
  { country_code: 'FJ', country: 'Fiji', code: '679' },
  { country_code: 'VU', country: 'Vanuatu', code: '678' },
  { country_code: 'SB', country: 'Solomon Islands', code: '677' },
  { country_code: 'TO', country: 'Tonga', code: '676' },
  { country_code: 'PG', country: 'Papua New Guinea', code: '675' },
  { country_code: 'NR', country: 'Nauru', code: '674' },
  {
    country_code: 'BN',
    country: 'Brunei Darussalam',
    code: '673',
    rule: 'XXX XXXX'
  },
  { country_code: 'NF', country: 'Norfolk Island', code: '672' },
  { country_code: 'TL', country: 'Timor-Leste', code: '670' },
  {
    country_code: 'BQ',
    country: 'Bonaire, Sint Eustatius & Saba',
    code: '599'
  },
  { country_code: 'CW', country: 'Curaçao', code: '599' },
  { country_code: 'UY', country: 'Uruguay', code: '598', rule: 'X XXX XXXX' },
  { country_code: 'SR', country: 'Suriname', code: '597', rule: 'XXX XXXX' },
  { country_code: 'MQ', country: 'Martinique', code: '596' },
  { country_code: 'PY', country: 'Paraguay', code: '595', rule: 'XXX XXX XXX' },
  { country_code: 'GF', country: 'French Guiana', code: '594' },
  { country_code: 'EC', country: 'Ecuador', code: '593', rule: 'XX XXX XXXX' },
  { country_code: 'GY', country: 'Guyana', code: '592' },
  { country_code: 'BO', country: 'Bolivia', code: '591', rule: 'X XXX XXXX' },
  {
    country_code: 'GP',
    country: 'Guadeloupe',
    code: '590',
    rule: 'XXX XX XX XX'
  },
  { country_code: 'HT', country: 'Haiti', code: '509' },
  { country_code: 'PM', country: 'Saint Pierre & Miquelon', code: '508' },
  { country_code: 'PA', country: 'Panama', code: '507', rule: 'XXXX XXXX' },
  { country_code: 'CR', country: 'Costa Rica', code: '506', rule: 'XXXX XXXX' },
  { country_code: 'NI', country: 'Nicaragua', code: '505', rule: 'XXXX XXXX' },
  { country_code: 'HN', country: 'Honduras', code: '504', rule: 'XXXX XXXX' },
  {
    country_code: 'SV',
    country: 'El Salvador',
    code: '503',
    rule: 'XXXX XXXX'
  },
  { country_code: 'GT', country: 'Guatemala', code: '502', rule: 'X XXX XXXX' },
  { country_code: 'BZ', country: 'Belize', code: '501' },
  { country_code: 'FK', country: 'Falkland Islands', code: '500' },
  { country_code: 'LI', country: 'Liechtenstein', code: '423' },
  { country_code: 'SK', country: 'Slovakia', code: '421', rule: 'XXX XXX XXX' },
  {
    country_code: 'CZ',
    country: 'Czech Republic',
    code: '420',
    rule: 'XXX XXX XXX'
  },
  { country_code: 'MK', country: 'Macedonia', code: '389', rule: 'XX XXX XXX' },
  {
    country_code: 'BA',
    country: 'Bosnia & Herzegovina',
    code: '387',
    rule: 'XX XXX XXX'
  },
  { country_code: 'SI', country: 'Slovenia', code: '386', rule: 'XX XXX XXX' },
  { country_code: 'HR', country: 'Croatia', code: '385' },
  { country_code: 'XK', country: 'Kosovo', code: '383', rule: 'XXXX XXXX' },
  { country_code: 'ME', country: 'Montenegro', code: '382' },
  { country_code: 'RS', country: 'Serbia', code: '381', rule: 'XX XXX XXXX' },
  { country_code: 'UA', country: 'Ukraine', code: '380', rule: 'XX XXX XX XX' },
  {
    country_code: 'SM',
    country: 'San Marino',
    code: '378',
    rule: 'XXX XXX XXXX'
  },
  { country_code: 'MC', country: 'Monaco', code: '377', rule: 'XXXX XXXX' },
  { country_code: 'AD', country: 'Andorra', code: '376', rule: 'XX XX XX' },
  { country_code: 'BY', country: 'Belarus', code: '375', rule: 'XX XXX XXXX' },
  { country_code: 'AM', country: 'Armenia', code: '374', rule: 'XX XXX XXX' },
  { country_code: 'MD', country: 'Moldova', code: '373', rule: 'XX XXX XXX' },
  { country_code: 'EE', country: 'Estonia', code: '372' },
  { country_code: 'LV', country: 'Latvia', code: '371', rule: 'XXX XXXXX' },
  { country_code: 'LT', country: 'Lithuania', code: '370', rule: 'XXX XXXXX' },
  { country_code: 'BG', country: 'Bulgaria', code: '359' },
  { country_code: 'FI', country: 'Finland', code: '358' },
  { country_code: 'CY', country: 'Cyprus', code: '357', rule: 'XXXX XXXX' },
  { country_code: 'MT', country: 'Malta', code: '356', rule: 'XX XX XX XX' },
  { country_code: 'AL', country: 'Albania', code: '355', rule: 'XX XXX XXXX' },
  { country_code: 'IS', country: 'Iceland', code: '354', rule: 'XXX XXXX' },
  { country_code: 'IE', country: 'Ireland', code: '353', rule: 'XX XXX XXXX' },
  { country_code: 'LU', country: 'Luxembourg', code: '352' },
  { country_code: 'PT', country: 'Portugal', code: '351', rule: 'X XXXX XXXX' },
  { country_code: 'GI', country: 'Gibraltar', code: '350', rule: 'XXXX XXXX' },
  { country_code: 'GL', country: 'Greenland', code: '299', rule: 'XXX XXX' },
  {
    country_code: 'FO',
    country: 'Faroe Islands',
    code: '298',
    rule: 'XXX XXX'
  },
  { country_code: 'AW', country: 'Aruba', code: '297', rule: 'XXX XXXX' },
  { country_code: 'ER', country: 'Eritrea', code: '291', rule: 'X XXX XXX' },
  { country_code: 'SH', country: 'Saint Helena', code: '290', rule: 'XX XXX' },
  { country_code: 'KM', country: 'Comoros', code: '269', rule: 'XXX XXXX' },
  { country_code: 'SZ', country: 'Swaziland', code: '268', rule: 'XXXX XXXX' },
  { country_code: 'BW', country: 'Botswana', code: '267', rule: 'XX XXX XXX' },
  { country_code: 'LS', country: 'Lesotho', code: '266', rule: 'XX XXX XXX' },
  { country_code: 'MW', country: 'Malawi', code: '265', rule: '77 XXX XXXX' },
  { country_code: 'NA', country: 'Namibia', code: '264', rule: 'XX XXX XXXX' },
  { country_code: 'ZW', country: 'Zimbabwe', code: '263', rule: 'XX XXX XXXX' },
  { country_code: 'RE', country: 'Réunion', code: '262', rule: 'XXX XXX XXX' },
  {
    country_code: 'MG',
    country: 'Madagascar',
    code: '261',
    rule: 'XX XX XXX XX'
  },
  { country_code: 'ZM', country: 'Zambia', code: '260', rule: 'XX XXX XXXX' },
  {
    country_code: 'MZ',
    country: 'Mozambique',
    code: '258',
    rule: 'XX XXX XXXX'
  },
  { country_code: 'BI', country: 'Burundi', code: '257', rule: 'XX XX XXXX' },
  { country_code: 'UG', country: 'Uganda', code: '256', rule: 'XX XXX XXXX' },
  { country_code: 'TZ', country: 'Tanzania', code: '255', rule: 'XX XXX XXXX' },
  { country_code: 'KE', country: 'Kenya', code: '254', rule: 'XXX XXX XXX' },
  { country_code: 'DJ', country: 'Djibouti', code: '253', rule: 'XX XX XX XX' },
  { country_code: 'SO', country: 'Somalia', code: '252', rule: 'XX XXX XXX' },
  { country_code: 'ET', country: 'Ethiopia', code: '251', rule: 'XX XXX XXXX' },
  { country_code: 'RW', country: 'Rwanda', code: '250', rule: 'XXX XXX XXX' },
  { country_code: 'SD', country: 'Sudan', code: '249', rule: 'XX XXX XXXX' },
  {
    country_code: 'SC',
    country: 'Seychelles',
    code: '248',
    rule: 'X XX XX XX'
  },
  { country_code: 'SH', country: 'Saint Helena', code: '247', rule: 'XXXX' },
  {
    country_code: 'IO',
    country: 'Diego Garcia',
    code: '246',
    rule: 'XXX XXXX'
  },
  {
    country_code: 'GW',
    country: 'Guinea-Bissau',
    code: '245',
    rule: 'XXX XXXX'
  },
  { country_code: 'AO', country: 'Angola', code: '244', rule: 'XXX XXX XXX' },
  {
    country_code: 'CD',
    country: 'Congo (Dem. Rep.)',
    code: '243',
    rule: 'XX XXX XXXX'
  },
  {
    country_code: 'CG',
    country: 'Congo (Rep.)',
    code: '242',
    rule: 'XX XXX XXXX'
  },
  { country_code: 'GA', country: 'Gabon', code: '241', rule: 'X XX XX XX' },
  {
    country_code: 'GQ',
    country: 'Equatorial Guinea',
    code: '240',
    rule: 'XXX XXX XXX'
  },
  {
    country_code: 'ST',
    country: 'São Tomé & Príncipe',
    code: '239',
    rule: 'XX XXXXX'
  },
  { country_code: 'CV', country: 'Cape Verde', code: '238', rule: 'XXX XXXX' },
  { country_code: 'CM', country: 'Cameroon', code: '237', rule: 'XXXX XXXX' },
  {
    country_code: 'CF',
    country: 'Central African Rep.',
    code: '236',
    rule: 'XX XX XX XX'
  },
  { country_code: 'TD', country: 'Chad', code: '235', rule: 'XX XX XX XX' },
  { country_code: 'NG', country: 'Nigeria', code: '234' },
  { country_code: 'GH', country: 'Ghana', code: '233' },
  {
    country_code: 'SL',
    country: 'Sierra Leone',
    code: '232',
    rule: 'XX XXX XXX'
  },
  { country_code: 'LR', country: 'Liberia', code: '231' },
  { country_code: 'MU', country: 'Mauritius', code: '230' },
  { country_code: 'BJ', country: 'Benin', code: '229', rule: 'XX XXX XXX' },
  { country_code: 'TG', country: 'Togo', code: '228', rule: 'XX XXX XXX' },
  { country_code: 'NE', country: 'Niger', code: '227', rule: 'XX XX XX XX' },
  {
    country_code: 'BF',
    country: 'Burkina Faso',
    code: '226',
    rule: 'XX XX XX XX'
  },
  {
    country_code: 'CI',
    country: 'Côte d`Ivoire',
    code: '225',
    rule: 'XX XXX XXX'
  },
  { country_code: 'GN', country: 'Guinea', code: '224', rule: 'XXX XXX XXX' },
  { country_code: 'ML', country: 'Mali', code: '223', rule: 'XXXX XXXX' },
  { country_code: 'MR', country: 'Mauritania', code: '222', rule: 'XXXX XXXX' },
  { country_code: 'SN', country: 'Senegal', code: '221', rule: 'XX XXX XXXX' },
  { country_code: 'GM', country: 'Gambia', code: '220', rule: 'XXX XXXX' },
  { country_code: 'LY', country: 'Libya', code: '218', rule: 'XX XXX XXXX' },
  { country_code: 'TN', country: 'Tunisia', code: '216', rule: 'XX XXX XXX' },
  { country_code: 'DZ', country: 'Algeria', code: '213', rule: 'XXX XX XX XX' },
  { country_code: 'MA', country: 'Morocco', code: '212', rule: 'XX XXX XXXX' },
  {
    country_code: 'SS',
    country: 'South Sudan',
    code: '211',
    rule: 'XX XXX XXXX'
  },
  { country_code: 'IR', country: 'Iran', code: '98', rule: 'XXX XXX XXXX' },
  { country_code: 'MM', country: 'Myanmar', code: '95' },
  { country_code: 'LK', country: 'Sri Lanka', code: '94', rule: 'XX XXX XXXX' },
  {
    country_code: 'AF',
    country: 'Afghanistan',
    code: '93',
    rule: 'XXX XXX XXX'
  },
  { country_code: 'PK', country: 'Pakistan', code: '92', rule: 'XXX XXX XXXX' },
  { country_code: 'IN', country: 'India', code: '91', rule: 'XXXXX XXXXX' },
  { country_code: 'TR', country: 'Turkey', code: '90', rule: 'XXX XXX XXXX' },
  { country_code: 'VN', country: 'Vietnam', code: '84' },
  { country_code: 'KR', country: 'South Korea', code: '82' },
  { country_code: 'JP', country: 'Japan', code: '81', rule: 'XX XXXX XXXX' },
  { country_code: 'TH', country: 'Thailand', code: '66', rule: 'X XXXX XXXX' },
  { country_code: 'SG', country: 'Singapore', code: '65', rule: 'XXXX XXXX' },
  { country_code: 'NZ', country: 'New Zealand', code: '64' },
  {
    country_code: 'PH',
    country: 'Philippines',
    code: '63',
    rule: 'XXX XXX XXXX'
  },
  { country_code: 'ID', country: 'Indonesia', code: '62' },
  { country_code: 'AU', country: 'Australia', code: '61', rule: 'XXX XXX XXX' },
  { country_code: 'MY', country: 'Malaysia', code: '60' },
  {
    country_code: 'VE',
    country: 'Venezuela',
    code: '58',
    rule: 'XXX XXX XXXX'
  },
  { country_code: 'CO', country: 'Colombia', code: '57', rule: 'XXX XXX XXXX' },
  { country_code: 'CL', country: 'Chile', code: '56', rule: 'X XXXX XXXX' },
  { country_code: 'BR', country: 'Brazil', code: '55', rule: 'XX XXXXX XXXX' },
  { country_code: 'AR', country: 'Argentina', code: '54' },
  { country_code: 'CU', country: 'Cuba', code: '53', rule: 'XXXX XXXX' },
  { country_code: 'MX', country: 'Mexico', code: '52' },
  { country_code: 'PE', country: 'Peru', code: '51', rule: 'XXX XXX XXX' },
  { country_code: 'DE', country: 'Germany', code: '49' },
  { country_code: 'PL', country: 'Poland', code: '48', rule: 'XXX XXX XXX' },
  { country_code: 'NO', country: 'Norway', code: '47', rule: 'XXXX XXXX' },
  { country_code: 'SE', country: 'Sweden', code: '46', rule: 'XX XXX XXXX' },
  { country_code: 'DK', country: 'Denmark', code: '45', rule: 'XXXX XXXX' },
  {
    country_code: 'GB',
    country: 'United Kingdom',
    code: '44',
    rule: 'XXXX XXXXXX'
  },
  { country_code: 'AT', country: 'Austria', code: '43' },
  { country_code: 'YL', country: 'Y-land', code: '42' },
  {
    country_code: 'CH',
    country: 'Switzerland',
    code: '41',
    rule: 'XX XXX XXXX'
  },
  { country_code: 'RO', country: 'Romania', code: '40', rule: 'XXX XXX XXX' },
  { country_code: 'IT', country: 'Italy', code: '39' },
  { country_code: 'HU', country: 'Hungary', code: '36', rule: 'XXX XXX XXX' },
  { country_code: 'ES', country: 'Spain', code: '34', rule: 'XXX XXX XXX' },
  { country_code: 'FR', country: 'France', code: '33', rule: 'X XX XX XX XX' },
  { country_code: 'BE', country: 'Belgium', code: '32', rule: 'XXX XX XX XX' },
  {
    country_code: 'NL',
    country: 'Netherlands',
    code: '31',
    rule: 'X XX XX XX XX'
  },
  { country_code: 'GR', country: 'Greece', code: '30', rule: 'XXX XXX XXXX' },
  {
    country_code: 'ZA',
    country: 'South Africa',
    code: '27',
    rule: 'XX XXX XXXX'
  },
  { country_code: 'EG', country: 'Egypt', code: '20', rule: 'XX XXXX XXXX' },
  {
    country_code: 'KZ',
    country: 'Kazakhstan',
    code: '7',
    rule: 'XXX XXX XX XX'
  },
  {
    country_code: 'RU',
    country: 'Russian Federation',
    code: '7',
    rule: 'XXX XXX XXXX'
  },
  {
    country_code: 'PR',
    country: 'Puerto Rico',
    code: '1',
    rule: 'XXX XXX XXXX'
  },
  {
    country_code: 'DO',
    country: 'Dominican Rep.',
    code: '1',
    rule: 'XXX XXX XXXX'
  },
  { country_code: 'CA', country: 'Canada', code: '1', rule: 'XXX XXX XXXX' },
  { country_code: 'US', country: 'USA', code: '1', rule: 'XXX XXX XXXX' }
]

export { countryList }
