<template>
  <div class="account-number">
    <div class="account-left">
      <div>
        <el-form :model="submitForm" ref="submitForm">
          <el-form-item
            prop="value"
            :rules="{
              required: true,
              message: '请选择区号'
            }"
          >
            <el-select
              size="small"
              style="width:100%"
              v-model="submitForm.value"
              placeholder="请选择区号"
              filterable
              clearable
            >
              <el-option
                v-for="(item, i) in countryList"
                :key="item.country_code + i"
                :label="'+' + item.code + ' ' + item.country"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="phone"
            :rules="{
              required: true,
              message: '请输入手机号'
            }"
          >
            <el-input
              size="small"
              class="input-bottom"
              v-model="submitForm.phone"
              placeholder="输入手机号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              class="input-bottom"
              type="textarea"
              v-model="submitForm.note"
              placeholder="备注"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px"
          @click="submitFun"
          :loading="loading"
        >
          立即提交
        </el-button>
      </div>
      <!-- <logPage
        title="提交手机号记录"
        ref="searchIndex2Log"
        height="calc(100% - 205px)"
        :logList="logList"
        showType="2"
        @selectLog="selectLog"
      /> -->
    </div>
    <div class="account-right">
      <div class="top">
        <el-input
          size="small"
          placeholder="手机号"
          clearable
          v-model="searchForm.phone"
          style="width:220px"
        ></el-input>
        <el-button
          type="primary"
          size="mini"
          style=" margin-left:10px"
          @click="searchFun"
          :loading="loading"
        >
          查询
        </el-button>
        <el-button
          class="el-icon-refresh-left"
          size="mini"
          style=" margin-left:10px"
          @click="refresh"
          :loading="loading"
        >
          刷新
        </el-button>
      </div>
      <div class="content" v-loading="loading">
        <el-table
          style="margin-bottom:10px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
          header-row-class-name="tableheader"
          :data="list"
          border
          :height="windowsHeight - 210"
        >
          <!-- <el-table-column prop="id" label="id" width="200"></el-table-column> -->
          <el-table-column
            prop="phone"
            label="手机号"
            width="200"
          ></el-table-column>
          <el-table-column prop="tips" label="处理状态">
            <!-- <template slot-scope="scope">
              <el-link v-if="scope.row.state == 0" type="primary">
                已提交
              </el-link>
              <el-link v-else-if="scope.row.state == 1" type="success">
                成功
              </el-link>
              <el-link v-else-if="scope.row.state == -1" type="danger">
                失败
              </el-link>
              <el-link v-else type="warning">
                其他处理中
              </el-link>
            </template> -->
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.state == 0">已提交</el-tag>
              <el-tag type="danger" v-else-if="scope.row.state == -1">
                失败
              </el-tag>
              <el-tag
                type="success"
                v-else-if="scope.row.state == 1 && scope.row.uid"
              >
                处理成功
              </el-tag>
              <el-tag
                type="warning"
                v-else-if="scope.row.state == 1 && !scope.row.uid"
              >
                未注册或设置隐私无法获取
              </el-tag>
              <el-tag v-else type="warning">其他处理中</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="uid" label="uid"></el-table-column>
          <el-table-column prop="uname" label="用户名"></el-table-column>
          <el-table-column prop="err" label="错误信息"></el-table-column>
          <el-table-column prop="note" label="备注"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="endTime" label="结束时间"></el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page-size="limit"
          :currentPage="current"
          :pagerCount="5"
          :background="false"
          :sizesArr="[30, 50, 100]"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { blastPhone, blastPhonePage } from '@/api/dropCheck/phoneCheck'
import { countryList } from '@/assets/js/country'
// import { getLocalStorageFun, localStorageFun } from '@/plugins/logFunction'
export default {
  name: 'PhoneCheck',
  data() {
    return {
      submitForm: {
        value: '86',
        phone: '',
        note: ''
      },
      countryList: countryList,
      params: {},
      current: 1,
      limit: 30,
      total: 0,
      list: [],
      logList: [],
      searchForm: {
        phone: ''
      },

      loading: false //加载状态
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.getPage()
  },
  methods: {
    // 刷新
    refresh() {
      this.getPage()
    },
    // 查询
    searchFun() {
      this.current = 1
      this.getPage()
    },
    getPage() {
      this.loading = true
      blastPhonePage(this.current, this.limit, this.searchForm).then(data => {
        this.list = data.items
        this.total = data.all_count
        this.loading = false
      })
    },

    submitFun() {
      this.$refs['submitForm'].validate(valid => {
        if (valid) {
          let obj = {
            phone: '+' + this.submitForm.value + this.submitForm.phone,
            note: this.submitForm.note
          }
          this.loading = true
          blastPhone(obj).then(data => {
            this.loading = false
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
            this.current = 1
            this.submitForm.value = ''
            this.submitForm.phone = ''
            this.submitForm.note = ''
            this.getPage()
            this.$nextTick(() => {
              this.$refs['submitForm'].clearValidate()
            })
          }).catch(() => {
            this.loading = false
          })
          // localStorageFun('telegramAccount', this.submitForm)
          // this.logList = getLocalStorageFun('telegramAccount')
        }
      })
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    },
    selectLog(row) {
      this.$refs.searchIndex2Log.selectIndex = null
    }
  }
}
</script>

<style lang="scss" scoped>
.account-number {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  .account-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    .tips-text {
      position: absolute;
      top: 50%;
      font-size: 12px;
      font-weight: 300;
      color: #050505;
    }
    .input-bottom {
      margin-bottom: 10px;
    }
    // .el-form-item {
    //   margin-bottom: 10px !important;
    // }
    ::v-deep .el-form-item__error {
      top: 90% !important;
    }
    ::v-deep .el-input__inner {
      padding-right: 0;
    }
  }
  .account-right {
    width: calc(100% - 270px);
    height: 100%;
    // border: 1px solid #e5e5e5;

    padding-left: 5px;
    position: relative;
    overflow: hidden;
    .top {
      overflow: hidden;
      padding: 5px 10px;
      background: white;
      box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
      border-radius: 5px;
    }
    .content {
      margin-top: 10px;
      background: white;
      padding: 10px;
      box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
      border-radius: 5px;
    }
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
